// Here you can add other styles
.grid-column-mini {
    width: 25px;
}

.grid-column-small {
    width: 35px;
}

.grid-column-id {
    width: 50px;
}

.grid-column-date {
    width: 30px;
}

.grid-column-short {
    width: 110px;
}

.grid-column-short-75 {
    width: 75px;
}

.grid-column-budget {
    width: 130px;
}

.grid-column-medium {
    width: 180px;
}

.grid-column-large {
    width: 80%;
}

.grid-column-long {
    width: 250px;
}

.grid-column-25 {
    width: 25%;
}

.grid-column-35 {
    width: 35%;
}

.grid-column-50 {
    width: 50%;
}

.label {
    font-weight: bold;
    margin-right: 10px;
    margin-left: 5px;
}

.cardBody {
    padding-left: 25px;
    padding-top: 5px;
}

.formGeneric {
    width: 98%;
}

.projectDates {
    text-align: right;
    width: 85px;
}

.invalid-feedback-select {
    font-size: 80%;
    color: #e55353;
}

.cell-number {
    text-align: right !important;
    width: 160px;
}

.cell-tooltip-text {
    text-align: left !important;
    width: 100px;
}

.cell-tooltip-number {
    text-align: right !important;
    width: 100px;
}

.cell-action {
    text-align: center !important;
    width: 35px;
}

.display-block {
    display: block;
}

.date-size {
    width: 110px !important;
}

.invalid-permissions {
    color: red;
    text-align: center;
    padding: 20px;
}

.big-calendar {
    height: 450px;
}

.legend { list-style: none; margin-left:-40px; }
.legend li { float:left; margin-right: 15px; }
.legend span { border: 1px solid #ccc; float: left; width: 15px; height: 15px; margin: 3px; }

/* legend colors Calendar */
.legend .recommendations { background-color: #ebcc34;}
.legend .milestones { background-color: #34eb83; }
.legend .disbursements { background-color: #349beb; }
.legend .financialreports { background-color: #eb8398; }
.legend .narrativereports { background-color: #d9b2f7; }
.legend .evaluationreports { background-color: #34d8eb; }
.legend .auditrequirements { background-color: #eb8f34; }
.legend .closures { background-color: #f2b3b5; }


// Timeline
.timelineUser {
    font-style: italic;
    font-size: 14px !important;
}

.timelineMoney {
    font-weight: bold !important;
    font-size: 14px !important;
}

.timelineType {
    font-size: 14px !important;
}

.timelineShowMore {
    font-size: 13px !important;
}

.arrow {
    font-size: 18px;
    font-weight: bold;
    color:rgb(76, 122, 24);
    // background-color: rgb(76, 122, 24);
    padding: 5px;
}

// .arrow {
//     width: 120px;
//   }
  
//   .line {
//     margin-top: 14px;
//     width: 300px;
//     background: rgb(22, 66, 2);
//     height: 10px;
//     float: left;
//   }
  
//   .point {
//     width: 0;
//     height: 0;
//     border-top: 20px solid transparent;
//     border-bottom: 20px solid transparent;
//     border-left: 30px solid rgb(22, 66, 2);
//     float: right;
//   }

// Custom icon size (Command Buttons)
.dx-command-edit-with-icons .dx-icon {
    height: 24px;
    width: 24px;
    cursor: pointer; 
  }

  .dx-command-select {  
    width: 20px!important;  
    min-width: 20px!important;  
}  

.centered-cell {
    color: 'white';
    font-weight: 'bold';
    word-wrap: 'break-word'; 
    display: 'table';
    margin: 0 auto;
}

.white {
    color:white !important;
}

.negative-number {
    color:red !important;
    font-weight: bold;
}

.cell-number.lighgrey-disabled:disabled {
    background-color: lightgrey !important; /* Sets the background color to lighgrey */
    color: red !important; /* Optional: Sets the text color to red for better readability */
    justify-content: right;
    font-weight: bold;
}

.important-text {
    color:red !important;
    font-weight: bold;
}

.warning-row {
    background-color: #fcedbb !important;
}

// Master-Detail style
.dx-datagrid-rowsview
.dx-master-detail-row:not(.dx-datagrid-edit-form)
.dx-master-detail-cell, .dx-datagrid-rowsview
.dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
  background-color: #fcf6e3 !important;
}

// Cards with rotated text
.wrapper-vertical-orange {
    $border-color: rgba(orange, 0.5);
    width: 100%;
    min-height: 160px;
    position: relative;
    border: 2px solid $border-color;
    border-left: 40px solid $border-color;
    padding: 15px;
    color: rgba(black, 0.5);
    font-size: small;
    display: inline-block;
    
    h3 {
      font-size: 12px;
      color: black;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: bold;   // Added bold font weight
      
      position: absolute;
     // top: 20%;   
      bottom: top;
      left: 0;
      margin-left: -30px;
      margin-top: 110px;
      
      transform-origin: 0 0;
      transform: rotate(270deg);
    }
    
  }

  .wrapper-vertical-green {
    $border-color: rgba(rgb(76, 122, 24), 0.5);
    width: 100%;
    min-height: 160px;
    position: relative;
    border: 2px solid $border-color;
    border-left: 40px solid $border-color;
    padding: 15px;
    color: rgba(black, 0.5);
    font-size: small;
    display: inline-block;
    
    h3 {
      font-size: 12px;
      color: black;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: bold;   // Added bold font weight

      position: absolute;
      bottom: top;   
      left: 0;
      margin-left: -30px;
      margin-top: 110px;
      
      transform-origin: 0 0;
      transform: rotate(270deg);
    }
    
  }

  .c-sidebar-form {
      width: 235px !important;
      color: black;
  }

  .c-sidebar-form label {
    color: white;
}

.iconHighLighted {
    width: 33px;
    color: yellow;
}

.brand {
    font-weight: bold;
    color: #5b92e5 !important;
}

.MuiTimelineConnector-root {
    width:6px !important;
    min-height: 10px;
}

.cell-first-value {
    display: inline-block;
    width: 100%;
}
  
.cell-second-value {
    position: relative;
    display: table-cell;
    font-size: 13px;
    color:#eb8f34;
    width: 100%;
    float:right;
}

.font-small {
    font-size: 11px !important;
}

.test-message {
    font-size: 15px !important;
    color: red;
    font-weight: bold;
}

.info-text {
    font-size: 12px !important;
    text-align: right;
    font-style: italic;
}

.warning-3 {
    color:red !important;
    font-weight: bold;
}

.warning-2 {
    color:orange !important;
    font-weight: bold;
}

.warning-1 {
    color:green !important;
    font-weight: bold;
}

.margin-left-long {
    margin-left: 40px !important;
}

.helpBar {
    margin-left: 13px;
    margin-top: 50px;
    margin-right: 10px;
}

.c-header-toggler-small {
    min-width: 20px !important;
}

.nav-link {
    padding: 0.5rem 0.7rem !important;
}

.navbar {
    padding: 0.5rem 0.5rem !important;
}

#pivotgrid .dx-area-fields .dx-area-box .dx-column-indicators .dx-header-filter:not(.dx-header-filter-empty) {
    color: green;
  }

#pivotgrid .dx-area-fields .dx-area-box .dx-area-field-content .dx-header-filter:not(.dx-header-filter-empty) {
color: green !important;
font-weight: bold !important;
}

.dx-pivotgrid .dx-grandtotal {
    background-color: #faf2d1 !important;
}

.dx-pivotgrid .dx-total {
    background-color: #f9e38d !important;
    font-weight: bold !important;
}

.total-count {
    display: flex;
    justify-content: right; 
    align-items: right;    
    margin-top: 8px;
    padding-right: 15px;
}

.info-message {
    font-size: 11px !important;
    font-style: italic !important;
    color: red;
    font-weight: bold;
}

.small-input {
    padding: 6px !important;
    width: 95px !important;
}

.audit-text {
    font-style: italic;
    font-size: 10 px !important;
}

.audit-value {
    font-style: italic;
    color: green;
    font-size: 10 px !important;
}